<template>
  <!-- 答案解析界面 -->
  <div class="questionnaire">
    <van-nav-bar :title="baseinfo.qsname"
                 left-text="" />
    <div class="banner"></div>
    <div class="toExaminedWjuan"
         style="position: absolute;right: 15px;top: 70px;">
      <img style="width:75px;"
           src="@assets/img/toExamined-end.png">
    </div>
    <div class="content">
      <!-- v-for="(bigType, qtIdx) in qsTiKu"
         :key="qtIdx"
         v-show="bigType.opt.length > 0" -->
      <!-- 题型信息 -->
      <!-- <div class="qsType"
           v-if="bigType.opt.length > 0">
        <span class="typeName">{{ bigType.type }}</span>
      </div> -->
      <!-- 题目内容详情 -->
      <div v-if="answerData.length > 0">
        <div class="qsContent"
             v-for="(item, index) in answerData"
             :key="index">
          <div class="qsTit"
               :class="[
              isClick == true ? 'clickCls' : '',
              typeof item.answer == 'number'
                ? item.answer == 0
                  ? 'tips'
                  : ''
                : typeof item.answer == 'string'
                ? item.answer != ''
                  ? ''
                  : 'tips'
                  : typeof item.answer == 'array'
                ?  item.answer.length > 0
                ? ''
                : 'tips':'',
            ]">
            {{(item.opflag == 5 || item.opflag == 6)? `${item.optitle}`:`${item.index}.${item.optitle}` }}
          </div>
          <div class="title_pic"
               v-show="item.picurl">
            <van-image fit="contain"
                       :src="item.picurl ? filesvrurl + '/' + item.picurl + '.png' : ''"
                       @click="imagePreview(filesvrurl + '/' + item.picurl + '.png')" />
          </div>
          <!-- 单选题 -->
          <van-radio-group class="singleChoice"
                           v-model="item.answer"
                           v-if="item.opflag == 0">
            <!-- v-if="bigType.type == '单选题'" -->
            <div v-for="(opt, opIdx) in item.options"
                 :key="opIdx">
              <van-radio :name="abList[opIdx]"
                         :disabled="true">
                <span class="iico">{{ abList[opIdx] }}</span>
                {{ opt.label }}
              </van-radio>
              <!-- 单选时有填空 -->
              <div v-if="opt.canInput && item.answer == abList[opIdx]"
                   class="can_input"
                   style="padding-left: 25px;margin-bottom: 10px;">
                <van-field v-model="opt.inputTxt"
                           placeholder="请输入"
                           readonly
                           style="padding: 5px 15px;border: 1px solid #969799;" />
              </div>
              <div class="option_pic"
                   v-show="item.pics[opIdx]">
                <van-image width="100%"
                           height="100%"
                           :src="
                    item.pics[opIdx]
                      ? filesvrurl + '/' + item.pics[opIdx] + '.png'
                      : ''
                  "
                           @click="
                    imagePreview(filesvrurl + '/' + item.pics[opIdx] + '.png')
                  " />
              </div>
            </div>
          </van-radio-group>
          <!-- 多选题 -->
          <van-checkbox-group v-model="item.answer"
                              v-else-if="item.opflag == 1"
                              :disabled="true">
            <!-- v-else-if="bigType.type == '多选题'" -->
            <div v-for="(opt, opIdx) in item.options"
                 :key="opIdx">
              <van-checkbox :name="abList[opIdx]">
                <span class="iico">{{ abList[opIdx] }}</span>
                {{ opt }}
              </van-checkbox>
              <div class="option_pic"
                   v-show="item.pics[opIdx]">
                <van-image width="100%"
                           height="100%"
                           :src="
                    item.pics[opIdx]
                      ? filesvrurl + '/' + item.pics[opIdx] + '.png'
                      : ''
                  "
                           @click="
                    imagePreview(filesvrurl + '/' + item.pics[opIdx] + '.png')
                  " />
              </div>
            </div>
          </van-checkbox-group>
          <!-- 简答题 -->
          <van-field class="brief"
                     v-if="item.opflag == 2"
                     v-model="item.answer"
                     rows="10"
                     autosize
                     label=""
                     type="textarea"
                     placeholder="请输入答案"
                     show-word-limit
                     :disabled="true" />
          <!-- v-if="bigType.type == '简答题'" -->
          <!-- 附近题 -->
          <van-uploader v-if="item.opflag == 3"
                        v-model="item.answer"
                        multiple
                        :max-count="1">
            <!-- v-if="bigType.type == '附件题'" -->
            <van-button icon="plus"
                        type="info">上传文件</van-button>
          </van-uploader>
          <!-- 打分题 -->
          <div v-if="item.opflag == 4"
               style="display: flex; align-items: center; flex-wrap: wrap">
            <!-- v-if="bigType.type == '打分题'" -->
            <van-rate :count="item.options.length"
                      :disabled="true"
                      v-model="item.answer" />
            <div style="margin-left: 10px">
              {{ item.options[item.answer - 1] }}
            </div>
          </div>
          <!-- 分割线 -->
          <van-divider v-if="item.opflag == 6"
                       dashed
                       :style="{  borderColor: '#000000', padding: '16px 0' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { getCommFinishPjDetail } from "@api/wxpx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
export default {
  data () {
    return {
      userInfo,
      baseinfo: {},
      answerData: [],
      // 题号
      bigOrder: ["一、", "二、", "三、", "四、", "五、"],
      // 题目类型
      qsTypeArr: ["单选题", "多选题", "简答题", "附件题", "打分题"],
      // 题库
      qsTiKu: [
        {
          type: "单选题",
          opt: [],
        },
        {
          type: "多选题",
          opt: [],
        },
        {
          type: "简答题",
          opt: [],
        },
        {
          type: "附件题",
          opt: [],
        },
        {
          type: "打分题",
          opt: [],
        },
      ],
      // 除了备注和分割线的题目
      ddjfdTiKu: [],
      // abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      isClick: false,
      loadingData: false,
      mautoid: parseInt(this.$route.query.mautoid),
      filesvrurl: "", // 图片前缀
    };
  },
  computed: {
    abList () {
      let ary = []
      for (let i = 1; i <= 100; i++)
      {
        ary.push(i + '')
      }
      return ary
    }
  },
  created () {
    window.T = this;
    this.getPjDetailData();
  },
  methods: {
    // 预览图片
    imagePreview (val) {
      ImagePreview([val]);
    },
    getPjDetailData () {
      let empid = this.userInfo.empid;
      let empname = this.userInfo.username;
      getCommFinishPjDetail({
        mautoid: this.$route.query.mautoid,
        empid: empid,
        username: empname,
      }).then((res) => {
        if (res.iserror == 0)
        {
          this.baseinfo = res.baseinfo[0];
          this.filesvrurl = res.baseinfo[0].filesvrurl;
          // this.answerData = res.data;
          let index = 0
          res.data.forEach((item) => {
            if (item.opflag != 5 && item.opflag != 6)
            {
              this.ddjfdTiKu.push(item);
            }
            // 单选题时判读是否填空
            if (item.opflag == 0)
            {
              let obj = {};
              index += 1
              obj.index = index
              obj.opflag = item.opflag;
              obj.optitle = item.optitle ? item.optitle.replace(/\\n/g, "<br/>") : "";
              obj.picurl = item.picurl;
              obj.pics = item.itemurl ? item.itemurl.split(",") : [];
              obj.tips = "";
              obj.answer = item.opres.indexOf('{v8_can_input_v8}') != -1 ? item.opres.split('{v8_can_input_v8}')[0] : item.opres
              obj.option = item.oplist.split("|");
              // obj.options = it.oplist.split("|");
              const iddx = this.abList.indexOf(obj.answer)
              obj.options = obj.option.map((el, idx) => {
                let redo = {}
                let canInput = el.indexOf('{v8_can_input_v8}') != -1 ? true : false
                redo = {
                  label: el.replace(/{v8_can_input_v8}/g, ""),
                  singleTxt: el.replace(/{v8_can_input_v8}/g, ""),
                  canInput: canInput,
                  inputTxt: idx == iddx ? item.opres.split('{v8_can_input_v8}')[1] : ''
                };
                return redo;
              });
              obj.autoid = item.autoid;
              this.answerData.push(obj);
            } else if (item.opflag == 3 || item.opflag == 1)
            {
              let obj = {};
              index += 1
              obj.index = index
              obj.opflag = item.opflag
              obj.optitle = item.optitle;
              obj.picurl = item.picurl;
              obj.pics = item.itemurl ? item.itemurl.split(",") : [];
              obj.tips = "";
              obj.answer = item.opres.split(",");
              obj.options = item.oplist.split("|");
              obj.autoid = item.autoid;
              this.answerData.push(obj);
            } else if (item.opflag == 4)
            {
              let obj = {};
              index += 1
              obj.index = index
              obj.opflag = item.opflag
              obj.optitle = item.optitle;
              obj.picurl = item.picurl;
              obj.tips = "";
              obj.answer = parseInt(item.opres);
              obj.options = item.oplist.split("|");
              obj.autoid = item.autoid;
              this.answerData.push(obj);
            } else
            {
              let obj = {};
              if (item.opflag != 5 && item.opflag != 6)
              {
                index += 1
              }
              obj.index = index
              obj.opflag = item.opflag
              obj.optitle = item.optitle;
              obj.picurl = item.picurl;
              obj.pics = item.itemurl ? item.itemurl.split(",") : [];
              obj.tips = "";
              obj.answer = item.opres;
              obj.options = item.oplist.split("|");
              obj.autoid = item.autoid;
              this.answerData.push(obj);
            }
          });
          // this.qsTiKu.forEach((value, key) => {
          //   this.answerData.forEach((item) => {
          //     let type = this.qsTypeArr[item.opflag];
          //     if (type == value.type) {
          //       if (type == "附件题" || type == "多选题") {
          //         let obj = {};
          //         obj.title = item.optitle;
          //         obj.picurl = item.picurl;
          //         obj.pics = item.itemurl ? item.itemurl.split(",") : [];
          //         obj.tips = "";
          //         obj.answer = item.opres.split(",");
          //         obj.options = item.oplist.split("|");
          //         obj.autoid = item.autoid;
          //         value.opt.push(obj);
          //       } else if (type == "打分题") {
          //         let obj = {};
          //         obj.title = item.optitle;
          //         obj.picurl = item.picurl;
          //         obj.tips = "";
          //         obj.answer = parseInt(item.opres);
          //         obj.options = item.oplist.split("|");
          //         obj.autoid = item.autoid;
          //         value.opt.push(obj);
          //       } else {
          //         let obj = {};
          //         obj.title = item.optitle;
          //         obj.picurl = item.picurl;
          //         obj.pics = item.itemurl ? item.itemurl.split(",") : [];
          //         obj.tips = "";
          //         obj.answer = item.opres;
          //         obj.options = item.oplist.split("|");
          //         obj.autoid = item.autoid;
          //         value.opt.push(obj);
          //       }
          //     }
          //   });
          // });
          this.loadingData = true;
        }
      });
    },
    // 点击返回
    // onClickLeft () {
    //   this.$router.push("/toEvaluated");
    // },
  },

};
</script>

<style lang="less" scoped>
.questionnaire {
  background: #fff;
  height: 100vh;
  /deep/.van-radio__label--disabled,
  /deep/.van-checkbox__label--disabled {
    color: #333;
  }
  /deep/.van-radio__icon--disabled.van-radio__icon--checked .van-icon,
  /deep/.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
    color: #fff;
  }
  /deep/.van-radio__icon--disabled .van-icon,
  /deep/.van-checkbox__icon--disabled .van-icon {
    background-color: #1989fa;
    border-color: #1989fa;
  }
  /deep/.van-rate__icon--disabled {
    color: #333;
  }
  /deep/.van-rate__icon--disabled.van-rate__icon--full {
    color: #ee0a24;
  }
  /deep/.van-field__control:disabled {
    color: #333;
    -webkit-text-fill-color: #333;
  }
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    background: url("~@/assets/img/examination1.png") no-repeat;
    background-size: cover;
    background-position-y: -150px;
  }
  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
    .qsType {
      margin-bottom: 28px;
      border-bottom: 1px solid #ccc;
      padding: 0 30px;
      padding-bottom: 28px;
      .typeName {
        margin-right: 15px;
        font-weight: bold;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      padding: 36px 0;
      padding: 0 30px;
      color: #333;
      font-size: 32px;
      margin-bottom: 30px;
      .qsTit {
        margin-bottom: 42px;
      }
      .title_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      .option_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 填空题样式
      .tk {
        div {
          .van-cell {
            /deep/.van-cell__title {
              width: 4em;
              margin-right: 0;
            }
          }
          .zqdn {
            /deep/.van-cell__title {
              width: 5.3em;
              margin-right: 0;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
      // 评分样式
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        background: #e6e6e6;
        border-radius: 42px;
        padding: 0 15px;
        font-size: 32px;
        margin-bottom: 16px;
        span:first-child {
          display: flex;
          align-items: center;
        }
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      // 答案解析样式
      .analysis {
        background: #e6e6e6;
        border-radius: 42px;
        padding: 30px;
        font-size: 32px;
        font-family: Source Han Sans SC;
        color: #333;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    // padding-bottom: 20px;
    height: 186px;
    line-height: 186px;
    .van-button {
      width: 80%;
    }
  }
  .clickCls.tips {
    color: red !important;
  }
}
</style>
